import 'owl.carousel';
import 'magnific-popup';

export default function artist() {
	$( '#artist-music .owl-carousel' ).owlCarousel( {
		items: 3,
		loop: true,
		dots: false,
		nav: true,
		margin: 60,
		navText: ['<span class="sr-only">Prev</span>', '<span class="sr-only">Next</span>'],
		responsive: {
			0: {
				items: 1,
				margin: 0
			},
			768: {
				items: 2,
				margin: 60
			},
			992: {
				items: 3,
				margin: 60
			}
		}
	} );

	$( '#artist-videos a' ).magnificPopup( {
		type: 'iframe',
		gallery: {
			enabled: true
		}
	} );
}