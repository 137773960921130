import 'magnific-popup';

export default function nav() {
	$( '#header .hamburger' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( this ).addClass( 'is-active' );
		$( '#nav-overlay' ).fadeIn();
		$( '#nav-collapse' ).addClass( 'open' );
	} );

	$( '#header #nav-collapse .nav-close' ).on( 'click', function ( e ) {
		e.preventDefault();
		$( '#header .hamburger' ).removeClass( 'is-active' );
		$( '#nav-overlay' ).fadeOut();
		$( '#nav-collapse' ).removeClass( 'open' );
	} );

	$( '.menu-links .search' ).magnificPopup( {
		type: 'inline'
	} );

	$( '#header #nav-collapse>nav>ul>li.local>a' ).on( 'click', function ( e ) {
		e.preventDefault();

		$( '#header #nav-collapse .nav-close' ).trigger( 'click' );

		const href = $( this ).attr( 'href' );
		$( 'html, body' ).animate( {
			scrollTop: $( href ).offset().top
		}, 1500 );
	} );
}