import nav from './components/nav';
import home from './components/home';
import artist from './components/artist';
import newsletter from './components/newsletter';

$( document ).ready( function ( $ ) {
	nav();
	home();
	artist();
	newsletter();
} );